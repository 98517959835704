import React from 'react';
import classes from './Seal.module.css';

const Seal = (props) => {
  const size = props.size || 'small'; // "large", "medium" or "small" default "small"

  let imgSrc, imgClass;
  switch (size.toLowerCase()) {
    case 'medium':
      imgSrc = '//siegel.exali.de/siegel/Haftpflicht_Siegel_2_3e980b14bd8321d40168ceb2c45392ba.png';
      imgClass = classes.Medium;
      break;
    case 'large':
      imgSrc = '//siegel.exali.de/siegel/Haftpflicht_Siegel_0_3e980b14bd8321d40168ceb2c45392ba.png';
      imgClass = classes.Large;
      break;
    default:
      imgSrc = '//siegel.exali.de/siegel/Haftpflicht_Siegel_1_3e980b14bd8321d40168ceb2c45392ba.png';
      imgClass = classes.Small;
  }

  const ImageBySize = <img border="0" src={imgSrc} alt="Haftpflicht-Siegel" className={imgClass} />;

  return (
    <a
      rel="nofollow noreferrer"
      href="https://www.exali.de/siegel/Bebensee-IT-GmbH"
      target="_blank"
      title="Mehr über die IT-Haftpflicht der Bebensee IT GmbH"
    >
      {ImageBySize}
    </a>
  );
};

export default Seal;
